import _objectSpread from "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.slice.js";
import { mapGetters } from 'vuex';
var path = process.env.VUE_APP_BASE_API;
export default {
  name: "customPic",
  props: {
    picType: {
      type: String,
      required: false,
      default: "avatar"
    },
    picSrc: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: function data() {
    return {
      path: path
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('user', ['userInfo'])), {}, {
    avatar: function avatar() {
      console.log(this.userInfo);
      if (this.picSrc === '') {
        if (this.userInfo.headerImg !== '' && this.userInfo.headerImg.slice(0, 4) === "http") {
          return this.userInfo.headerImg;
        }
        return this.path + this.userInfo.headerImg;
      } else {
        if (this.picSrc !== '' && this.picSrc.slice(0, 4) === "http") {
          return this.picSrc;
        }
        return this.path + this.picSrc;
      }
    },
    file: function file() {
      if (this.picSrc && this.picSrc.slice(0, 4) !== "http") {
        return this.path + this.picSrc;
      }
      return this.picSrc;
    }
  })
};