import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: "媒体库",
      visible: _vm.drawer
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.drawer = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-around",
      "flex-wrap": "wrap",
      "padding-top": "40px"
    }
  }, _vm._l(_vm.picList, function (item, key) {
    return _c("el-image", {
      key: key,
      staticClass: "header-img-box-list",
      attrs: {
        src: item.url && item.url.slice(0, 4) !== "http" ? _vm.path + item.url : item.url
      },
      nativeOn: {
        click: function click($event) {
          return _vm.chooseImg(item.url, _vm.target, _vm.targetKey);
        }
      }
    }, [_c("div", {
      staticClass: "header-img-box-list",
      attrs: {
        slot: "error"
      },
      slot: "error"
    }, [_c("i", {
      staticClass: "el-icon-picture-outline"
    })])]);
  }), 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };